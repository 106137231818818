import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import CTA from "../components/cta"

// import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';

import { motion } from "framer-motion"

const FreeAgency = () => (
  <Layout>
    <SEO title="FreeAgency - UK Based Freelance Web Designer/Developer" />
    <div className="container mx-auto px-4 max-w-7xl relative z-20">
      <div className="rounded-2xl overflow-hidden relative">
        <StaticImage className="absolute left-0 top-0 w-full h-full rounded-2xl overflow-hidden round-img z-10 work-hero" src="../images/freeagency-thumb.jpg" alt="FreeAgency" />
        <div className="w-full bg-zinc-900 bg-opacity-60 md:bg-opacity-20 bg-gradient-to-t from-zinc-900 to-transparent pt-24 md:pt-36 rounded-2xl relative z-20">
            <div className="p-8 md:p-12 lg:p-16 text-white  w-full">
                      <motion.h1 initial={{ opacity: 0, y: 15 }} whileInView={{ opacity: 1, y: 0 }} viewport={{ once: true }} className="text-5xl md:text-6xl flex flex-wrap lg:text-7xl md:max-w-sm lg:max-w-lg xl:max-w-xl font-medium leading-tight md:leading-tight lg:leading-tight tracking-tighter">FreeAgency</motion.h1>

              <div className="grid grid-cols-1 md:grid-cols-1 gap-8 md:gap-10 lg:gap-20 xl:gap-24 pt-6 md:pt-10">
          <motion.div initial={{ opacity: 0, y: 15 }} whileInView={{ opacity: 1, y: 0, transition: { delay: 0.075 } }} viewport={{ once: true }}>
            <div className="uppercase tracking-wider text-sm md:text-base font-medium mb-3 md:mb-4">
                About the Client
            </div>
            <div className="text-base md:text-lg lg:text-xl lg:leading-normal text-white md:opacity-90 font-light">
              <p>
                A groundbreaking app that makes organizing sports teams and finding free agents a breeze.
              </p>
            </div>
          </motion.div>
        </div>
            </div>
          </div>
      </div>
        
        
    </div>

    <motion.div initial={{ opacity: 0, y: 15 }} whileInView={{ opacity: 1, y: 0, transition: { delay: 0.125 } }} viewport={{ once: true }} className="relative z-20 pt-16 md:pt-24 lg:pt-36">
      <div className="container mx-auto px-4 max-w-7xl">
        <div className="grid grid-cols-1 md:grid-cols-12 gap-5 md:gap-10 lg:gap-20 xl:gap-24">
        <div className="md:col-span-8">
          <div className="text-sm md:text-base lg:text-lg text-zinc-500 space-y-3 md:space-y-4">
            <p>
              The client had clear direction for his web app and needed a designer to make it come to life. I used his research and sketches as a starting point for my design process and after many iterations and rounds of feedback, I presented the final designs in Figma.
            </p>
            <p>I also provided the client with 2 logo ideas & a styleguide.</p>
          </div>
          
        </div>
        
        <div className="md:col-span-4">
        <div className="uppercase tracking-wider text-sm md:text-base font-medium mb-2 md:mb-2.5 md:mt-2">
                Services I Provided
            </div>
            <div className="text-sm md:text-base text-zinc-700">
              <p>
               Web Design, Logo Design, Styleguide
              </p>
            </div>
          </div>
          
        </div>
        <div className="rounded-2xl px-10 py-7 md:px-16 md:py-8 lg:px-20 lg:py-12 bg-gray-100 mt-7 md:mt-12">
          <blockquote>
            <div>
              <svg className="h-12 w-12 text-zinc-900 opacity-25" fill="currentColor" viewBox="0 0 32 32" aria-hidden="true">
                <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
              </svg>
              <p className="mt-6 text-2xl md:text-3xl font-medium text-zinc-900">Donatas had incredible communication and responsiveness to feedback. Blew the other designers we had out of the water.</p>
            </div>
            <footer className="mt-6">
              <p className="text-base font-medium text-zinc-700">B. Lippeveld</p>
              <p className="text-base font-regular text-zinc-500">Founder</p>
            </footer>
          </blockquote>
        </div>
        
      </div>
      <div className="mx-auto px-4 max-w-[1536px] pt-10 md:pt-16">
        <div className="grid grid-cols-12 gap-4 md:gap-6 lg:gap-8">
          <StaticImage className="h-auto col-span-12 rounded-xl md:rounded-2xl overflow-hidden border border-zinc-200 shadow-lg round-img-sm" objectPosition="center top" src="../images/freeagency-1.png" alt="FreeAgency" />
          <StaticImage className="h-auto col-span-12 md:col-span-6 rounded-xl md:rounded-2xl overflow-hidden border border-zinc-200 shadow-lg round-img-sm" objectPosition="center top" src="../images/freeagency-3.png" alt="FreeAgency" />
          <StaticImage className="h-auto col-span-12 md:col-span-6 rounded-xl md:rounded-2xl overflow-hidden border border-zinc-200 shadow-lg round-img-sm" objectPosition="center top" src="../images/freeagency-4.png" alt="FreeAgency" />
          <StaticImage className="h-auto col-span-12 md:col-span-6 rounded-xl md:rounded-2xl overflow-hidden border border-zinc-200 shadow-lg round-img-sm" objectPosition="center top" src="../images/freeagency-5.png" alt="FreeAgency" />
          <StaticImage className="h-auto col-span-12 md:col-span-6 rounded-xl md:rounded-2xl overflow-hidden border border-zinc-200 shadow-lg round-img-sm" objectPosition="center top" src="../images/freeagency-6.png" alt="FreeAgency" />
          <StaticImage className="h-auto col-span-12 rounded-xl md:rounded-2xl overflow-hidden border border-zinc-200 shadow-lg round-img-sm" objectPosition="center top" src="../images/freeagency-style.png" alt="FreeAgency" />

        </div>
      </div>
      
    </motion.div>
    <CTA></CTA>
  </Layout>
)

export default FreeAgency
